[v-cloak] {
    display: none;
}

@backgroundColor : #fcfcfc;
@colorStateOrange: #fa934e;
@colorStateGreen : #0dcb0d;

body {
    background-color: @backgroundColor;
}

ul {
    list-style: square;
}

.table-caption {
    font-weight: bold;

    &:after {
        content: ":";
    }
}

// Overwride the f*** pink color in bootstrap-cosmo info
.alert-info {
    color           : #0c5460;
    background-color: #d1ecf1;
    border-color    : #bee5eb;
}

.dialog-modal {
    max-height: 50vh;
    overflow-y: auto;
}
