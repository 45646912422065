[v-cloak] {
  display: none;
}
body {
  background-color: #fcfcfc;
}
ul {
  list-style: square;
}
.table-caption {
  font-weight: bold;
}
.table-caption:after {
  content: ":";
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.dialog-modal {
  max-height: 50vh;
  overflow-y: auto;
}
#captureCanvas {
  max-width: 400px;
  max-height: 400px;
}
